/* eslint-disable import/no-cycle */
import React from "react";

import { webV1Url } from "@bluecrew/web-react-core/src/constants";
import { Link } from "@bluecrew/web-react-core";

import {
  UserColumnJobWrapper,
  UserColumnStyledAvatar,
  UserColumnName,
  UserColumnWrapper,
  UserColumnTextWrapper,
  UserColumnNameStyled
} from "./styledComponents";
import { ShiftMessageInfo, TimesheetsJobInfo } from "../../../types/Shift.types";
import { AttributeJobInfo } from "./AttributeJobInfo";
import { AttributeMessageSent } from "./AttributeMessageSent";

import { 
  capitalizeFirstLetter, 
  formatName, 
  convertTZ, 
  dateToMonthAndDay 
} from "../../../../../utility/misc";
import { useAppSelector } from "../../../../../redux";
import { getUserType } from "../../../../../redux/selectors/auth";
import { TimesheetSupportUserTypes } from "../../../types/Payroll.types";


export type UserColumnCellProps = {
  firstName: string;
  lastName: string;
  externalId: string;
  jobInfo: TimesheetsJobInfo;
  profilePic?: URL;
  className?: string;
  shiftMessageInfo?: ShiftMessageInfo;
};

export const UserColumnCell = ({
  firstName,
  lastName,
  externalId,
  jobInfo,
  profilePic,
  className,
  shiftMessageInfo,
}: UserColumnCellProps) => {
  const userType = useAppSelector(getUserType);
  const isSupportUser = TimesheetSupportUserTypes.includes(userType);

  const userNameText = formatName(firstName, lastName);

  const messageWasSent = shiftMessageInfo?.messageSent;

  const tooltipText = messageWasSent
    ? `${capitalizeFirstLetter(shiftMessageInfo.recipientUserName!)} was messaged by ${capitalizeFirstLetter(shiftMessageInfo.senderUserName!)}
    on ${dateToMonthAndDay(convertTZ(shiftMessageInfo.messageDate!, jobInfo.timezone))} about all timestamp issues on this shift`
    : "";

  const workerProfileLink = `${webV1Url}/app.html#!/worker/${externalId}`;

  return (
    <UserColumnWrapper className={className}>
      <UserColumnStyledAvatar 
        fullName={userNameText || " "} 
        size="smd" 
        imgSrc={profilePic} 
      />
      <UserColumnTextWrapper>  
        <UserColumnName>
          <Link href={workerProfileLink} target="blank">
            <UserColumnNameStyled>{userNameText}</UserColumnNameStyled>
          </Link>
        </UserColumnName>
        <UserColumnJobWrapper>
          <AttributeJobInfo
            name={jobInfo.name}
            start={jobInfo.start}
            end={jobInfo.end}
            timezone={jobInfo.timezone}
          />
        </UserColumnJobWrapper>
      </UserColumnTextWrapper>

      {messageWasSent && isSupportUser && (
        <AttributeMessageSent tooltipMessage={tooltipText}/>
      )}
    </UserColumnWrapper>
  );
};