import { colorTheme } from "@bluecrew/blueprint-web";
import { Link, Divider, Banner } from "@bluecrew/web-react-core";
import styled from "styled-components";
import { palette as p } from "styled-tools";
import { assetUrl } from "../../api/bluecrew/constants";

interface FilledStatusProps {
  activeSchedule: boolean;
  useBigFont?: boolean;
}

export const StyledDivider = styled(Divider)`
  color: ${colorTheme.dashboardDropdownRowDividerColor};
  opacity: 1;
`;

export const InviteUserButton = styled.div`
  width: 32px;
  height: 32px;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 8px;
  background-image: url(${assetUrl}/invite-crew-member.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-color: transparent;
  border-radius: 4px;
  &:hover {
    cursor: pointer;
  }
`;

export const ProgressBarContainer = styled.div`
  flex-direction: column;
  display: flex;
  text-align: right;
  justify-content: center;
  text-align: left;
`;

export const StyledFilledStatus = styled.span<FilledStatusProps>`
  margin-right: 12px;
  color: ${({ activeSchedule }) => (activeSchedule ? "#3246F0" : "#BABBC4")};
  font-weight: bold;
  font-size: ${({ useBigFont }) => (useBigFont ? "24px" : "20px")};
  display: flex;
  width: 60px;
`;

export const FillStatusContainer = styled.div`
  height: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  text-align: right;
  color: #000000;
  margin-bottom: 2px;
`;

export const StyledDisabledLink = styled(Link)`
  color: ${p("platinum")};
  opacity: 0.5;
  text-decoration: none;

  :hover {
    cursor: not-allowed;
  }
`;

export const RowContainer = styled.div<{ justifyContent?: string }>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || "center"};
`;

export const StyledDeleteButton = styled.button`
  display: flex;
  justify-content: center;
  background-color: #FFFFFF;
  border: none;
  padding: 0;
  
  width: 100%;

  color: #BABBC4;

  div {
     opacity: ${(props) => props.disabled && "0.5"}
    }
  }
`;

export const ActionsContainer = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
`;

export const TableHeaderColumnContainer = styled.div<{
  justifyContent?: string;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent}`};
`;

const ScheduleRequestTableContainer = styled.div`
  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;
  
  .schedule-name {
    width: 220px;
    word-break: break-word;
  }

  .request-dropdown {
    width: 55px;
  }
  
  .schedule-time {
    width: 150px;
  }
  
  .progress-bar {
    width: 100px;
  }
  
  .alert-icon,
  .direct-hire{
    width: 35px;
  }
  
  .action-buttons{
    width: 80px;
    padding-left: 10px;
  }
  
  table {
    /* Make sure the inner table is always as wide as needed */
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;

    thead {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      /* identical to box height, or 133% */

      text-transform: uppercase;
      
      color: ${colorTheme.dashboardTableHeaderTextColor};
    }
    
    .actionDropdown,
    .directInviteButton {
      width: 30px;
    }

    tbody {
      .schedule-name {
        margin-bottom: 12px;
      }
      
      .sub-level-row {
        background-color: ${colorTheme.dashboardSecondaryColor};
        &:hover {
          background-color: ${p("frost")};
          cursor: pointer; 
          ${StyledDeleteButton} {
            background-color: ${p("frost")};
            color: #e72e3e;
            cursor: pointer;
          }
        }
        border-bottom: 1px solid ${colorTheme.dashboardSubComponentSecondaryBorderColor};
      }

      .top-level-row, .top-level-row-individual-shift {
        background-color: ${colorTheme.dashboardTableHeaderBackgroundColor};
        border-bottom: 1px solid ${colorTheme.dashboardSubComponentBorderColor};
        border-top: 1px solid ${colorTheme.dashboardSubComponentBorderColor};
        :hover {
          background-color: ${p("frost")};
          cursor: pointer;
        }
      }


      .top-level-row-repeating-schedule {
        background-color: ${p("frost")};
        border-bottom: 1px solid ${colorTheme.dashboardSubComponentBorderColor};
        border-top: 1px solid ${colorTheme.dashboardSubComponentBorderColor};
        :hover {
          cursor: pointer;
        }
      }
      
      .empty-header-row {
        background-color: ${p("frost")};
        border-bottom: 1px solid ${colorTheme.dashboardSubComponentBorderColor};
        border-top: 1px solid ${colorTheme.dashboardSubComponentBorderColor};
        :hover {
          cursor: pointer;
        }
      }
      
      .show-request-row {
        background-color: ${colorTheme.dashboardSecondaryColor};
      }
      
      tr {
        text-align: center;
      }
    }
    
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 16px 0px;

      &.collapse {
        width: 0.0000000001%;
        rotate(180);
      }
      
      :first-child {
        text-align: left;
      }
      
      :last-child {
        border-right: 0;
        text-align: right;
      }
    }
  }
`;

export const DashboardSchedulesAndIndividualShiftsTableContainer = styled(
  ScheduleRequestTableContainer,
)`
  tbody {
    .schedule-name {
      padding-left: 20px;
      margin-bottom: 12px;
      word-break: break-word;
    }
  }

  .schedule-name {
    width: 240px;
    justify-content: left;
  }

  thead {
    tr {
      .schedule-name {
        padding-left: 0px;
      }
      th {
        .schedule-name {
          padding-left: 20px;
        }
      }
    }
  }

  .supervisor {
    width: 140px;
    justify-content: left;
  }

  .schedule-date {
    width: 160px;
    justify-content: left;
  }

  .schedule-time {
    width: 120px;
    justify-content: left;
  }

  .work-days {
    justify-content: center;
    width: 190px;
  }

  .fill-status {
    justify-content: left;
    width: 190px;
  }

  .wage {
    justify-content: left;
    width: 100px;
  }

  .action-buttons {
    width: 60px;
  }

  .alert-icon {
    padding-left: 10px;
  }
`;

export const JobRequestCrewMemberTableStyles = styled.div`
  max-height: 250px;
  overflow: auto;

  thead tr th {
    z-index: 2;
    position: sticky;
    top: 0;
  }

  table {
    border-collapse: collapse;
  }

  th {
    z-index: 2; 
    /* With border-collapse, we must use box-shadow or psuedo elements
    for the header borders */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */
    background-color: #F2F3F7;
    text-transform: uppercase;
    color: ${colorTheme.dashboardTableHeaderTextColor};
  }

  /* Basic Demo styling */
  table {
    width: 100%;
  }
  table td {
    padding: 16px;
  }
  tbody tr {
    z-index: 1
  }
  thead {
    z-index: 2;
  }

  th,
  td {
    padding: 16px 16px;

    &.collapse {
      width: 0.0000000001%;
      rotate(180);
    }

    :first-child {
      text-align: left;
    }
    
    border-right: 0;
    text-align: right;
  }

}
`;

export const FlexCenterRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SeeMoreRequestsContainer = styled.div`
  padding: 4px;
`;

export const EmptyHeaderRowName = styled.div`
  color: ${({ theme }) => theme.colorTokens.gray700};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  width: 140px;
`;

export const StyledBanner = styled(Banner)`
  margin: 2.5rem auto;
`;
