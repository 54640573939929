import React from "react";
import {
  BreakDurationInput,
  ShiftAdjustBodyText,
  ShiftAdjustBreakDurationEditingContainer,
  ShiftAdjustBreakInputContainer,
} from "./styledComponents";

type ShiftAdjustBreakDurationProps = {
  label: string;
  setBreakDuration: (d?: number) => void;
  breakDuration?: number;
};

export const ShiftAdjustBreakDuration = ({
  label,
  breakDuration,
  setBreakDuration,
}: ShiftAdjustBreakDurationProps) => {
  return (
    <ShiftAdjustBreakDurationEditingContainer>
      <ShiftAdjustBodyText>{label}</ShiftAdjustBodyText>
      <ShiftAdjustBreakInputContainer>
        <BreakDurationInput
          value={breakDuration}
          min={0}
          onChange={(d) => d.value !== null && setBreakDuration(d.value)} // onChange returns a Date | null. Check to ensure nulls do not make through into the state
          suffix={" min"}
          maxFractionDigits={0}
        />
      </ShiftAdjustBreakInputContainer>
    </ShiftAdjustBreakDurationEditingContainer>
  );
};
