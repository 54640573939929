import React from "react";
import {
  ShiftAdjustInputNumber,
  ShiftAdjustTimeInputContainer,
  AmPmPickerDropdown,
  ShiftAdjustTooltip,
  ShiftAdjustTimeDisplayContentSpacers,
} from "./styledComponents";

type TimePickerWithKeyboardInputProps = {
  date?: Date;
  setDate: (d: Date) => void;
}

export const TimePickerWithKeyboardInput = ({
  date,
  setDate,
}: TimePickerWithKeyboardInputProps) => {

  const updateDate = (previousDate: Date, hours: number, minutes: number, amPm: string) => {
    const d = new Date(previousDate)
    d.setMinutes(minutes)
    if (amPm === "PM" && hours !== 12) {
      d.setHours(hours + 12)
    }
    else if (amPm === "AM" && hours === 12) {
      d.setHours(0) // covers for the edge case of turning 12:xx pm into 12:xx am
    } else {
      d.setHours(hours)
    }
    setDate(d)
  }

  const selectAmPmValues = [
    {value: "AM", label: "AM"},
    {value: "PM", label: "PM"},
  ]

  const getAmPm = (hours: number) => {
    return hours > 11 ? "PM" : "AM"
  }

  const normalizeMinuteValue = (minutes: number) => {
    if (minutes < 0) {
      return 0
    }
    if (minutes > 59) {
      return 59
    }
    return minutes
  }

  if (!date) {
    return  (
      <>
        <ShiftAdjustTooltip>
          Please select a date
        </ShiftAdjustTooltip>
        <ShiftAdjustTimeInputContainer>
          <ShiftAdjustInputNumber
            showButtons
            buttonLayout={"vertical"}
            data-testid={"HourPicker"}
            disabled />
          <ShiftAdjustTimeDisplayContentSpacers>
            :
          </ShiftAdjustTimeDisplayContentSpacers>
          <ShiftAdjustInputNumber
            showButtons
            buttonLayout={"vertical"}
            data-testid={"MinutePicker"}
            disabled />
          <AmPmPickerDropdown
            value={"AM"}
            options={selectAmPmValues}
            data-testid={"AmPmPicker"}
            disabled />
        </ShiftAdjustTimeInputContainer>
      </>)
  }
  return (
    <ShiftAdjustTimeInputContainer>
      <ShiftAdjustInputNumber
        showButtons
        buttonLayout={"vertical"}
        data-testid={"HourPicker"}
        value={(date.getHours() + 11) % 12 + 1}
        min={1}
        max={12}
        onChange={
          e => updateDate(
            date,
            ((!e.value || e.value > 12 ) ? 12 : e.value),
            date.getMinutes(),
            getAmPm(date.getHours()))
        } />
      <ShiftAdjustTimeDisplayContentSpacers>
        :
      </ShiftAdjustTimeDisplayContentSpacers>
      <ShiftAdjustInputNumber
        showButtons
        buttonLayout={"vertical"}
        data-testid={"MinutePicker"}
        value={date.getMinutes()}
        placeholder={"00"}
        prefix={date.getMinutes() < 10 ? "\u200b0" : ""} // zero-width space is included to avoid a bug in primereact
        min={0}
        max={59}
        onChange={
          e => updateDate(
            date,
            (date.getHours() + 11) % 12 + 1,
            normalizeMinuteValue(e.value != null ? e.value : 0 ),
            getAmPm(date.getHours()))} />
      <AmPmPickerDropdown
        value={getAmPm(date.getHours())}
        options={selectAmPmValues}
        data-testid={"AmPmPicker"}
        onChange={
          e => updateDate(
            date,
            (date.getHours() + 11) % 12 + 1,
            date.getMinutes(),
            e.value)} />
    </ShiftAdjustTimeInputContainer>
  )
}
