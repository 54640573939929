/* eslint-disable import/no-cycle */
import React, { useState } from "react";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { PayrollStatusReason, PayrollStatusReasonAndText } from "../../../types/Payroll.types";
import { TimesheetsShiftInfo, ShiftTimes } from "../../../types/Shift.types";
import { SyledModal } from "./styledComponents";
import { ShiftAdjustHeader } from "./ShiftAdjustHeader";
import { ShiftAdjustFooter } from "./ShiftAdjustFooter";
import { getReasonTextFromCode, validateShiftTimes } from "../../../shiftUtils";
import { ShiftAdjustBody } from "./ShiftAdjustBody";
import { ShiftAdjustReasonCodeSelect } from "./ShiftAdjustReasonCodeSelect";
import { ErrorText } from "../../../styledComponents";
import {calculateEditModalHours} from "../../../payrollDataProcessors";

type HeaderProps = {
  headerText: string;
  subHeaderText: string;
};

type FooterProps = {
  footerButtonLabel: string;
};

export type ReasonCodeAndText = {
  code: PayrollStatusReason;
  text: string;
};

export type ShiftAdjustment = {
  reason: PayrollStatusReasonAndText;
  clockIn?: Date;
  clockOut?: Date;
  breakDurationMins?: number;
};

export type ShiftAdjustModalProps = {
  headerProps: HeaderProps;
  footerProps: FooterProps;
  shiftInfo: TimesheetsShiftInfo;
  adjustmentCodes: Array<PayrollStatusReason>;
  adjustmentCodesWithTimeEdit: Array<PayrollStatusReason | undefined>;
  shiftAdjustModalVisible: boolean;
  setShiftAdjustModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  shiftAdjustmentHandler: (adjustment: ShiftAdjustment) => unknown; // success or not
  defaultShiftTimes: ShiftTimes;
};

/*
 * The TZ conversions allow us to give the illusion of zoned time in the prime react datetime picker
 * The prime picker interprets datetime as local regardless of tz:
 *   1. date is passed to component in client locale
 *   2. date is converted to job locale and passed to picker
 *   3. picker interprets only date time component as client locale (with offset applied), ignoring zone
 *   4. user edits date (with offset applied) in client locale
 *   5. date is passed from picker back to outer component for submission (in client locale with offset applied)
 *   6. date is force converted from job locale to client locale on submission (removing offset)
 * converting date to client locale on submission gives desired experience / behavior
 * https://github.com/primefaces/primereact/issues/4128
 */
export const ShiftAdjustModal = ({
  headerProps,
  footerProps,
  shiftInfo,
  adjustmentCodes,
  adjustmentCodesWithTimeEdit,
  shiftAdjustModalVisible,
  setShiftAdjustModalVisible,
  shiftAdjustmentHandler,
  defaultShiftTimes,
}: ShiftAdjustModalProps) => {
  const clientTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const jobTz = shiftInfo.job.timezone;
  const shiftLocalStart = defaultShiftTimes.clockIn ? utcToZonedTime(zonedTimeToUtc(defaultShiftTimes.clockIn, clientTz), jobTz) : undefined;
  const shiftLocalEnd = defaultShiftTimes.clockOut ? utcToZonedTime(zonedTimeToUtc(defaultShiftTimes.clockOut, clientTz), jobTz) : undefined;

  const [reasonCodeAndText, setReasonCodeAndText] = useState<ReasonCodeAndText>();
  const [clockIn, setClockIn] = useState<Date | undefined>(shiftLocalStart);
  const [clockOut, setClockOut] = useState<Date | undefined>(shiftLocalEnd);
  const [breakDurationMins, setBreakDurationMins] = useState<number | undefined>(
    defaultShiftTimes.breakDurationMins,
  );
  const [error, setError] = useState<string>();

  const { headerText, subHeaderText } = headerProps;
  const { user, job } = shiftInfo;
  const { footerButtonLabel } = footerProps;

  const shiftDuration = calculateEditModalHours(clockIn, clockOut, breakDurationMins).toFixed(2)

  const allowTimeAdjustment =
    reasonCodeAndText && adjustmentCodesWithTimeEdit.includes(reasonCodeAndText.code);
  const reasonCodeAndTextList = adjustmentCodes.map((reason) => ({
    code: reason,
    text: getReasonTextFromCode(reason),
  }));

  const getValidationMessage = () => {
    if (!reasonCodeAndText) {
      return "Adjustment reason must be present.";
    }
    if (allowTimeAdjustment) {
      return validateShiftTimes({
        clockIn,
        clockOut,
        breakDurationMins,
      });
    }
    return;
  };

  const confirmationHandler = () => {
    const errorMessage = getValidationMessage();
    setError(errorMessage);
    if (!errorMessage) {
      setShiftAdjustModalVisible(false);
      const adjustment = { clockIn, clockOut, breakDurationMins };
      shiftAdjustmentHandler({
        reason: reasonCodeAndText!, // safe after validation
        ...adjustment,
      });
    }
  };

  return (
    <SyledModal
      header={
        <ShiftAdjustHeader
          headerText={headerText}
          subHeaderText={subHeaderText}
          jobDate={job.start}
          userInfo={{
            firstName: user.firstName,
            lastName: user.lastName,
            jobInfo: job,
            profilePic: user.profilePic,
            externalId: user.externalId,
          }}
          shiftHours={shiftDuration}
        />
      }
      visible={shiftAdjustModalVisible}
      onHide={() => setShiftAdjustModalVisible(false)}
      footer={
        <ShiftAdjustFooter
          footerButtonLabel={footerButtonLabel}
          onCancel={() => {
            setShiftAdjustModalVisible(false);
          }}
          onConfirm={confirmationHandler}
        />
      }
    >
      <>
        <ShiftAdjustReasonCodeSelect
          reasonCodeAndText={reasonCodeAndText}
          reasonCodeAndTextList={reasonCodeAndTextList}
          setReasonCodeAndText={setReasonCodeAndText}
        />
        {allowTimeAdjustment && (
          <ShiftAdjustBody
            clockIn={clockIn}
            clockOut={clockOut}
            breakDurationMins={breakDurationMins}
            setClockIn={setClockIn}
            setClockOut={setClockOut}
            setBreakDurationMins={setBreakDurationMins}
          />
        )}
        {error && <ErrorText>{error}</ErrorText>}
      </>
    </SyledModal>
  );
};
