import { useQuery } from "@tanstack/react-query";
// eslint-disable-next-line import/no-cycle
import { getAuditTrail, getCompanyShiftsAndShiftMessages } from "../finance";
// eslint-disable-next-line import/no-cycle
import { getPayrollWeek } from "../payroll";
// eslint-disable-next-line import/no-cycle
import {
  mapFinanceResponseWithMessagesToFinanceTableData,
} from "../../../containers/Payroll/Timesheets/utility/TimesheetsMappingUtil";
import QueryKeys from "./queryKeys";

export const useGetCompanyShifts = (date: Date, companyId?: string) => {
  const payrollWeek = getPayrollWeek(date);
  return useQuery({
    queryKey: [...QueryKeys.Finance_GetCompanyShifts, companyId, payrollWeek],
    queryFn: () => getCompanyShiftsAndShiftMessages(companyId!, payrollWeek),
    enabled: !!companyId,
    select: (data) => mapFinanceResponseWithMessagesToFinanceTableData(data),
  });
};

export const useGetAuditTrail = (
  companyId: string,
  jobId: string,
  shiftId: number,
  userId: string,
) =>
  useQuery({
    queryKey: ["auditTrail", companyId, jobId, shiftId, userId],
    queryFn: () => getAuditTrail(companyId, jobId, shiftId, userId),
    refetchInterval: false,
  });
