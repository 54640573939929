// COMPANY - SAGA
// =============================================================================

import { SagaIterator } from "redux-saga";
import { Effect, call, put, takeLatest } from "redux-saga/effects";
import companyActions, {
  CheckCompanyAction,
  GetCompanyDetailsAction,
  SwitchCompanyAction,
} from "../actions/company";
import * as companyAPI from "../../api/bluecrew/company";
import { queryClient } from "../../queryClient";

function* getCompanyDetailsSaga(action: GetCompanyDetailsAction): Generator<Effect, void, any> {
  try {
    const response = yield call(companyAPI.getCompanyDetails, action.payload);
    yield put(companyActions.getCompanyDetails.success(response));
  } catch (error) {
    yield put(companyActions.getCompanyDetails.failure(error));
  }
}

function* switchCompanySaga(action: SwitchCompanyAction): Generator<Effect, void, any> {
  try {
    const response = yield call(companyAPI.switchCompany, action.payload);
    yield put(companyActions.switchCompany.success(response));
    queryClient.clear();
    // We need to do a hard redirect here to get the latest split.io
    // treatement and make sure user state is up to date (see sagas/auth).
    window.location.href = "/";
  } catch (error) {
    yield put(companyActions.switchCompany.failure(error));
  }
}

export type FetchUserCompaniesAction = {
  type: string;
  payload: FetchUserCompaniesPayload;
};

export type FetchUserCompaniesPayload = {
  parentCompanyId: number | null;
  shouldFetchAllCompanies: boolean;
  shouldFetchBranchCompanies: boolean;
};

// Generator<CallEffect<ReturnType<companyAPI.getAllCompanies>> | PutEffect<AnyAction>
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* userCompaniesSaga(_action: FetchUserCompaniesAction): Generator<Effect, void, any> {
  try {
    type CompanyForSwitcher = {
      company_id: number;
      name: string;
      url: string;
      city: string;
    };

    const res: { result: companyAPI.MyCompany[] } = yield call(companyAPI.getMyCompanies);
    const response: { companies: CompanyForSwitcher[] } = {
      companies: res.result.map((c) => ({
        company_id: c.companyId,
        name: c.companyName,
        url: c.companyLogoUrl,
        city: c.companyCity,
      })),
    };

    yield put(companyActions.userCompanies.success(response));
  } catch (error) {
    yield put(companyActions.userCompanies.failure(error));
  }
}

function* checkCompanySaga(action: CheckCompanyAction): Generator<Effect, void, any> {
  const { existingCompanyId } = action.payload;
  try {
    const companyDetails = yield call(companyAPI.getCompanyDetails, {
      companyId: existingCompanyId,
    });
    // super users can still fetch company data from companies they don't belong to
    if (!companyDetails.company.isUserCurrentCompany) {
      throw new Error("Super Admin doesn't belong to company");
    }
  } catch (error) {
    // This will throw an error, such as a 403, if the user is
    // no longer part of the company or if they are logged out
    // or if the user is a SUPER_USER
    // Reload the app to refresh company data.
    window.location.href = "/";
  }
}

export default {
  *watchGetCompanyDetailsSaga(): SagaIterator {
    yield takeLatest(companyActions.getCompanyDetails.request, getCompanyDetailsSaga);
  },
  *watchUserCompaniesSaga(): SagaIterator {
    yield takeLatest(companyActions.userCompanies.request.toString(), userCompaniesSaga);
  },
  *watchSwitchCompanySaga(): SagaIterator {
    yield takeLatest(companyActions.switchCompany.request, switchCompanySaga);
  },
  *watchCheckCompanySaga(): SagaIterator {
    yield takeLatest(companyActions.checkCompany, checkCompanySaga);
  },
};
