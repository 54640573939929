/**
 * CUSTOM_COGNITO_FORMS_SPLIT_NAME is a DEAD split that is no longer in use (as of 10/8/24)
 * It is left as is to serve as an example of how to structure split changes.
 */
import { CUSTOM_COGNITO_FORMS_SPLIT_NAME } from "../shared/constants";

const spoofLocalSplits = import.meta.env.VITE_SPOOF_LOCAL_SPLITS || "false";

const REAL_SPLIT_CONFIG = {
  core: {
    authorizationKey: import.meta.env.VITE_SPLIT_IO_BROWSER_API_KEY,
    key: "anonymous", // Won't have any user identifier initially, so it doesn't really matter what this key is
  },
};

const SPOOFED_SPLIT_CONFIG = {
  // To test locally add VITE_SPOOF_LOCAL_SPLITS=true to you .env file
  core: {
    authorizationKey: "localhost",
    key: "user_id",
  },
  /**
   * Add whatever split you want to test here.
   *
   * This can have more complex config than just "on" or "off"
   * @example
   * features: {
   *  'reporting_v2': 'on', // example with just a string value for the treatment
   *  'billing_updates': { treatment: 'visa', config: '{ "color": "blue" }' }, //example of a defined config
   *  'show_status_bar': { treatment: 'off', config: null } // example of a null config
   * },
   *
   * See this link for more details
   * https://help.split.io/hc/en-us/articles/360038825091-React-SDK#localhost-mode
   */
  features: {
    [CUSTOM_COGNITO_FORMS_SPLIT_NAME]: "on",
  },
};

export const SPLIT_CONFIG =
  String(spoofLocalSplits) === "false" ? REAL_SPLIT_CONFIG : SPOOFED_SPLIT_CONFIG;
