import { AddShiftFormTypes } from "./AddShiftForm.types";
import { differenceInHours, isAfter } from "date-fns";
import { MAX_SHIFT_LENGTH_HOURS, MIN_SHIFT_LENGTH_HOURS } from "../../../../../shared/constants";
import { validateShiftTimes } from "../../shiftUtils";
import { CompanyWorker } from "../../../../api/bluecrew/payroll";

export const getCreateNextShiftErrorMessage = (formValues: AddShiftFormTypes) => {
  const { clockIn, clockOut, breakDurationMins, position, supervisor } = formValues;

  if (!position) {
    return "Position must be selected";
  }
  if (!supervisor) {
    return "Supervisor must be selected";
  }
  if (clockIn && clockOut && differenceInHours(clockOut, clockIn) >= MAX_SHIFT_LENGTH_HOURS) {
    return `Shift length cannot exceed ${MAX_SHIFT_LENGTH_HOURS} hrs`;
  }
  if (clockIn && clockOut && differenceInHours(clockOut, clockIn) <= MIN_SHIFT_LENGTH_HOURS) {
    return `Shift length must be over ${MIN_SHIFT_LENGTH_HOURS} hrs`;
  }
  if (clockIn && clockOut && (isAfter(clockIn, new Date()) || isAfter(clockOut, new Date()))) {
    return "Shift times must be in the past";
  }

  return validateShiftTimes({
    clockIn,
    clockOut,
    breakDurationMins: breakDurationMins,
  });
};
export const getSelectWorkersErrorMessage = (selectedWorkers: Map<string, CompanyWorker>) => {
  if (selectedWorkers.size <= 0) {
    return "Atleast one worker must be selected";
  }
  return;
};
