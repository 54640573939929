import React from 'react';
import { useThemedStyletron } from '../../theme';
import { SvgPropType } from './SvgPropType';

export const EnvelopeIcon = (props: SvgPropType) => {
  const [, theme] = useThemedStyletron();
  const {
    width = 25,
    height = 20,
    color = theme.colors.ultramarine,
    stroke = theme.colors.white,
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 125 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M60,0 l50,0 a10,10 0 0,1 7,17 l-50,50 a10,10 0 0,1 -13,0 l-50,-50 a10,10 0 0,1 7,-17z"
        stroke={stroke}
        strokeWidth="0"
        fill={color}
      />
      <path
        d="M60,90 l54,0 a10,10 0 0,0 7,-7 l0,-60 -50,50 a15,15 0 0,1 -21,0 l-50,-50 0,60 a10,10 0 0,0 7,7z"
        stroke={stroke}
        strokeWidth="0"
        fill={color}
      />
    </svg>
  );
};
