import React from "react";
import { EnvelopeIcon } from "@bluecrew/blueprint-web";
// eslint-disable-next-line import/no-cycle
import { AlignmentContainer, } from "../styledComponents";
import { Tooltip } from "../../../../../components/Tooltip";
import { randomString, } from "../../../../../utility/misc";


export type AttributeMessageSentProps = {
  tooltipMessage: string; // We will use it later, this is scope of the task: https://ebpp.atlassian.net/browse/FIN-725
};

export const AttributeMessageSent = ({
  tooltipMessage,
}: AttributeMessageSentProps) => {
  const sendMessageIconWrapperElementId = `SentMessageIconId-${randomString(6)}`;
  return (
    <>
      <Tooltip position={"top"} target={`#${sendMessageIconWrapperElementId}`}>
        {tooltipMessage}
      </Tooltip>
      <AlignmentContainer id={sendMessageIconWrapperElementId} onClick={(e) => {e.stopPropagation()}}>
        <EnvelopeIcon/>
      </AlignmentContainer>
    </>
  );
};
