import { format } from "date-fns";

/**
 * Remove any any non-digit character from the string.
 * Ex: 123abcd4$ becomes 1234
 */
export function removeNonDigits(inputValue: string): string {
  return inputValue.replace(/[^\d]/g, "");
}

/**
 * Run a given code block (aka callback) and return it's result.
 * Useful if you want to use chained if else statements or a switch statement to assign a const value; it means you don't have to use let :)
 *
 * @example
 *  const successTitle: string = run(() => {
      if (successes.length > 0 && failures.length > 0) {
        return "Partial Success";
      }
      if (failures.length === 0) {
        return "Success";
      }
      if (successes.length === 0) {
        return "Failure";
      }
      return "";
    });
 */
export function run<T>(callback: () => T): T {
  return callback();
}

export function capitalizeFirstLetter(word: string): string {
  if (word.length === 0) {
    return "";
  }
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function formatName(first: string, last: string): string {
  return `${capitalizeFirstLetter(first)} ${capitalizeFirstLetter(last)}`;
}

export function randomString(len: number): string {
  const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let str = "";
  for (let i=0; i < len; i += 1) {
    const j = Math.floor(Math.random() * chars.length);
    str += chars[j];
  }
  return str;
}

/**
 * Convert date into local timezone
 * @param date date
 * @param timezoneString timezone
 * @returns date wrt to provided timezone
 */
export function convertTZ(date: (string | Date), timezoneString: string): Date {
  return new Date(
    (typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {
      timeZone: timezoneString,
    }),
  );
}

/**
 * Convert date to month and day-of-month string.
 * Example: Date '2024-07-11 14:56:34' converted to string 'July 11th'
 * @param d date
 * @returns month and day-of-month string representation of date
 */
export function dateToMonthAndDay(d: Date): string {
  return format(d, "LLLL do");
}
