// Finance-Service - API
// =============================================================================

import {
  AuditTrailResponse,
  PayrollTimesheetResponse,
} from "@bluecrew/finance-service-node-client";
import { financeApi } from "./constants";
// eslint-disable-next-line import/no-cycle
import { getTimesheetMessages } from "./intercom";
// eslint-disable-next-line import/no-cycle
import { PayrollTimesheetResponseWithMessages } from "../../containers/Payroll/types/Shift.types"

export const getCompanyShifts = async (
  companyId: string,
  week: number,
): Promise<PayrollTimesheetResponse> => {
  const url = `timesheet/${companyId}/payroll/${week}`;
  return financeApi.get(url).json();
};

export const getCompanyShiftsAndShiftMessages = async (
  companyId: string,
  week: number,
): Promise<PayrollTimesheetResponseWithMessages> => {
  const [payrollTimesheetResponse, timesheetMessagesResponse] = await Promise.all([
    getCompanyShifts(companyId, week),
    getTimesheetMessages(companyId, week)
  ]);

  return {
    payrollTimesheetResponse,
    timesheetMessagesResponse
  }
};

export const getAuditTrail = async (
  companyId: string,
  jobId: string,
  shiftId: number,
  userId: string,
): Promise<AuditTrailResponse> => {
  const url = `timesheet/${companyId}/job/${jobId}/shift/${shiftId}/cms/${userId}/audit`;
  return financeApi.get(url).json();
};
