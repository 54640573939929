import React, { useRef, useState } from "react";
import { StyledCancelButton, StyledOkButton, StyledSendButton } from "./styledComponents";
import { SendMessageContent } from "./SendMessageContent";
import { MessageSentContent } from "./MessageSentContent";
import { Toast } from "primereact/toast";
import { SpinnerWithOverlay } from "@bluecrew/web-react-core";
import { Dialog } from "../../../../../components/Dialog";
import { usePayrollSendMessage } from "../../../../../api/bluecrew/hooks/payroll";
import { TimesheetsShiftInfo } from "../../../types/Shift.types";

interface MessageModalProps {
  defaultModalMessage: string;
  cmFirstName: string;
  show: boolean;
  onModalClose: (e?: React.MouseEvent) => void;
  cmId: string;
  shiftInfo?: TimesheetsShiftInfo;
}

export const MessageModal = ({
  defaultModalMessage,
  cmFirstName,
  show,
  onModalClose,
  cmId,
  shiftInfo,
}: MessageModalProps) => {
  const [message, setMessage] = useState(defaultModalMessage);

  const [isCurrentMessageSent, setIsCurrentMessageSent] = useState(false);

  const toast = useRef<Toast>(null);
  const showErrorToast = (message: string) => {
    toast?.current?.show({ severity: "error", summary: "Error", detail: message });
    onModalClose();
  };

  const { mutate, isLoading } = usePayrollSendMessage(() => setIsCurrentMessageSent(true), showErrorToast);

  const sendMessage = (e) => {
    e.stopPropagation();
    mutate({
      userId: cmId,
      message,
      shiftInfo,
    });
  };

  const isMessageSent = isCurrentMessageSent || shiftInfo?.shiftMessageInfo?.messageSent;

  const footerContent = (
    <div>
      {isMessageSent ? (
        <StyledOkButton label="OK" onClick={onModalClose} />
      ) : (
        <>
          <StyledCancelButton
            label="Cancel"
            onClick={onModalClose}
            className="p-button-text"
            disabled={isLoading}
          />
          <StyledSendButton label="Send" onClick={sendMessage} disabled={isLoading || !message} />
        </>
      )}
    </div>
  );

  const headerText = isMessageSent ? "" : "Send a message";

  const modalContent = isMessageSent ? (
    <MessageSentContent cmFirstName={cmFirstName} />
  ) : (
    <SendMessageContent message={message} onChange={(e) => setMessage(e.target.value)} />
  );

  return (
    <>
      {isLoading && <SpinnerWithOverlay />}
      <Toast ref={toast} />
      <Dialog
        header={headerText}
        visible={show}
        onHide={onModalClose}
        footer={footerContent}
        closable={!isMessageSent}
      >
        {modalContent}
      </Dialog>
    </>
  );
};
