import React, { useState } from "react";
import { CompanyWorker } from "../../../../../api/bluecrew/payroll";
import { WorkerSearch } from "../Components/WorkerSearch";
import {
  SelectedWorkerText,
  SelectWorkersScreenContainer,
  WorkerSearchContainer,
} from "../styledComponents";
import { WorkerList } from "../Components/WorkerList";

export type SelectWorkersScreenProps = {
  allWorkers: Array<CompanyWorker>;
  selectedWorkers: Map<string, CompanyWorker>;
  setSelectedWorkers: (workers: Map<string, CompanyWorker>) => void;
};

export const SelectWorkersScreen = ({
  allWorkers,
  selectedWorkers,
  setSelectedWorkers,
}: SelectWorkersScreenProps) => {
  const [workerFilter, setWorkerFilter] = useState<string>("");

  return (
    <SelectWorkersScreenContainer>
      <WorkerSearchContainer>
        <WorkerSearch onChange={setWorkerFilter} />
        <SelectedWorkerText>{`${selectedWorkers.size} selected`}</SelectedWorkerText>
      </WorkerSearchContainer>
      <WorkerList
        allWorkers={allWorkers}
        selectedWorkers={selectedWorkers}
        setSelectedWorkers={setSelectedWorkers}
        filterText={workerFilter}
      />
    </SelectWorkersScreenContainer>
  );
};
