import React, {useState} from "react";
import {ReasonCellContentWrapper, ReasonCellReasonTextWrapper} from "./styledComponents";
import {PayrollStatusReason, TimesheetSupportUserTypes, TimesheetTabs} from "../../types/Payroll.types";
import {getReasonTextFromCode} from "../../shiftUtils";
import {TimesheetsShiftInfo, UserShiftLastUpdateBy} from "../../types/Shift.types";
import { ShiftDetailsModal } from "./ShiftDetails/ShiftDetailsModal";

export type ReasonColumnCellProps = {
  rowData: TimesheetsShiftInfo;
  activeTabIndex: TimesheetTabs;
};

function identifyUser(lastUpdateBy: UserShiftLastUpdateBy): string {
  return TimesheetSupportUserTypes.includes(lastUpdateBy.userType)
    ? "Bluecrew support"
    : lastUpdateBy.name;
}

export const ReasonColumnCell = ({ rowData, activeTabIndex }: ReasonColumnCellProps) => {
  const { lastUpdateBy, statusReason } = rowData.userShift;
  const userText = lastUpdateBy ? identifyUser(lastUpdateBy) : "Unknown User";
  const [isShiftDetailsModalOpen, setShiftDetailsModalOpen] = useState(false);

  return (
    <>
      <ReasonCellContentWrapper onClick={() => activeTabIndex === TimesheetTabs.REMOVED ? setShiftDetailsModalOpen(true) : setShiftDetailsModalOpen(false)}>
        <ReasonCellReasonTextWrapper>
          {`${userText}: ${getReasonTextFromCode(statusReason as PayrollStatusReason)}`}
        </ReasonCellReasonTextWrapper>
      </ReasonCellContentWrapper>
      {isShiftDetailsModalOpen && (
        <ShiftDetailsModal
          isModalOpen={isShiftDetailsModalOpen}
          onCloseModal={() => setShiftDetailsModalOpen(false)}
          shiftInfo={rowData}
        />
      )}
    </>
  );
};
