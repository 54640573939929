import { PayrollTimesheetResponse } from "@bluecrew/finance-service-node-client";
import { UserTimestamp } from "../../../api/bluecrew/payroll";
import { PayrollHoursStatus, PayrollStatusReason } from "./Payroll.types";
import { TimestampAttributeName } from "../shiftUtils";
import { UserType } from "../../../api/bluecrew/types";

export enum ShiftComponent {
  CLOCK_IN,
  CLOCK_OUT,
  BREAK_DURATION,
}

export type ShiftTimeMeta = {
  type: ShiftComponent;
  attributes: Array<TimestampAttributeName>;
};

export type ShiftTimestamp = ShiftTimeMeta & {
  time: Date;
  isDisputedTimeMissing: boolean;
  userTimestamp?: UserTimestamp;
  timeInDispute?: Date;
};

export type ShiftBreak = ShiftTimeMeta & {
  durationMinutes: number;
  breakTimestamps: Array<BreakTimestamps>;
  isDisputedDurationMissing: boolean;
  durationInDispute?: number;
};

export type UserShiftLastUpdateBy = {
  id?: string;
  name: string;
  userType: UserType;
  updatedAt: Date;
};

export enum WorkplaceAction {
  APPROVE,
  FLAG,
  UNDO,
}

export enum SupportAction {
  APPROVE,
  EDIT,
  EXCUSE,
  REJECT,
}

export type UserShift = {
  start: ShiftTimestamp;
  end: ShiftTimestamp;
  break: ShiftBreak;
  allowedWorkplaceActions: Array<WorkplaceAction>;
  allowedSupportActions: Array<SupportAction>;
  isAutoApproved: boolean;
  lastUpdateBy: UserShiftLastUpdateBy | undefined;
  status: PayrollHoursStatus;
  statusReason?: PayrollStatusReason;
};

export type ShiftThresholds = {
  warnRadius: number | null;
  blockRadius: number | null;
  earlyClockinBuffer: number;
  lateClockoutBuffer: number;
  minLunchDurationHours: number;
  expectedStart: Date;
  expectedEnd: Date;
};

export type BreakTimestamps = {
  start: UserTimestamp;
  end: UserTimestamp;
};

type TimestampAttributeValidateParams = {
  thresholds: ShiftThresholds;
  hoursStatus: PayrollHoursStatus;
  timestamp?: UserTimestamp;
  breaks?: Array<BreakTimestamps>;
};

export type TimestampAttribute = {
  name: TimestampAttributeName;
  validate: (params: TimestampAttributeValidateParams) => boolean;
};

export type LatLng = {
  lat: number;
  lng: number;
};

export type Break = {
  start: Date;
  end: Date;
};

export type TimesheetsJobInfo = {
  externalId: string;
  name: string;
  start: Date;
  end: Date;
  wage: number;
  timezone: string;
  shiftIndex: number;
  address: string;
  coordinates: LatLng;
  supervisorName?: string;
};

export type TimesheetsCompanyInfo = {
  externalId: string;
  name: string;
};

export type TimesheetsUserInfo = {
  externalId: string;
  firstName: string;
  lastName: string;
  profilePic?: URL;
  internalId?: number;
};

export type ShiftMessageInfo = {
  messageSent: boolean; // flag defining whether any message was sent related to this shift
  recipientUserName?: string; // Recipient user name
  senderUserName?: string; // Sender user name
  messageDate?: Date; // Date message was sent
};

export type TimesheetsShiftInfo = {
  company: TimesheetsCompanyInfo;
  job: TimesheetsJobInfo;
  shiftThresholds: ShiftThresholds;
  user: TimesheetsUserInfo;
  userShift: UserShift;
  userHoursId?: number;
  shiftMessageInfo?: ShiftMessageInfo;
};

export type ShiftKey = {
  jobId: string;
  cmId: string;
  shiftIndex: number;
};

export type ShiftTimes = {
  clockIn?: Date;
  clockOut?: Date;
  breakDurationMins?: number;
};

export type AuditEditRow = {
  editDescription: string; // line item under Edits made by:
  editTimestamp?: string; // when edit was made
  editUsername?: string; // who made the edit
  shiftInfo?: TimesheetsShiftInfo; // used by TimestampColumnCell
  rowKey: string;
};

export type TimesheetUserMessage = {
  id: number;
  sender_user_id: number;
  sender_user_first_name: string;
  recipient_user_id: number;
  recipient_user_first_name: string;
  external_job_id: string;
  time_segment_absolute_id: number;
  sent_at: Date;
};

export type TimesheetMessagesResponse = {
  externalCompanyId: string;
  week: string;
  messages: TimesheetUserMessage[];
};

export type PayrollTimesheetResponseWithMessages = {
  payrollTimesheetResponse: PayrollTimesheetResponse;
  timesheetMessagesResponse: TimesheetMessagesResponse;
}
