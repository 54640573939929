export default Object.freeze({
  Payroll_PayrollReports: ["payroll", "payrollReports"],
  Payroll_CompanyPayroll: ["payroll", "companyPayroll"],
  Payroll_PayrollHours: ["payroll", "payrollHours"],
  Payroll_CompanyPositions: ["payroll", "companyPositions"],
  Payroll_GetAllWorkers: ["payroll", "getAllWorkers"],
  Payroll_AddShift: ["payroll", "addShift"],
  Payroll_Action: ["payroll", "action"], // Prefix for all payroll actions. Helps disable overlapping mutations.
  Payroll_SendMessage: ["payroll", "sendMessage"],
  ManageUsers_Users: ["manageusers", "users"],
  ManageUsers_Companies: ["manageusers", "companies"],
  ManageUsers_EditRole: ["manageusers", "editRole"],
  ManageUsers_AddNewUser: ["manageusers", "addNewUser"],
  ManageUsers_UpdateUsersCompanies: ["manageusers", "updateUsersCompanies"],
  Companies_GetInfo: ["companies", "getCompaniesInfo"],
  Companies_GetAllV1: ["companies", "getAllCompaniesV1"],
  Message_Prepare: ["message", "prepare"],
  Message_Send: ["message", "send"],
  Finance_GetCompanyShifts: ["finance", "getCompanyShifts"],
  Signup_CreateSupervisorUser: ["signup", "createSupervisorUser"],
  Intercom_GetTimesheetMessages: ["intercom", "getTimesheetMessages"],
});
