import styled from "styled-components";
import { UserAvatar } from "@bluecrew/web-react-core";

export const UserColumnJobTitle = styled.div`
  height: 20px;
  font-family: ${({ theme }) => theme.typography.font250.fontFamily};
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  font-style: normal;
  display: flex;
  color: #55565F;
`;

export const UserColumnJobTimezone = styled.div`
  height: 20px;
  padding-left: 5px;
  font-family: ${({ theme }) => theme.typography.font200.fontFamily};
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  font-style: normal;
  display: flex;
  color: #888992;
`;

export const UserColumnJobShiftSchedule = styled.div`
  //styleName: Text / Normal Compact;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  height: 20px;
  display: flex;
  color: #888992;
  flex-direction: row;
`;

export const UserColumnJobWrapper = styled.div`
  width: 300px; // 215
  display: flex;
  flex-direction: column;
`;

export const UserColumnJobShiftWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const UserCellWrapper = styled.div`
  padding-top: 8px;
  padding-left: 16px;
  width: 375px; // 287
  height: 76px;
`;

export const UserColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const UserColumnStyledAvatar = styled(UserAvatar)`
  background: ${({ theme }) => theme.colorTokens.aqua100};

  > span {
    font-style: normal;
    font-family: ${({ theme }) => theme.typography.font350.fontFamily};
    font-weight: ${({ theme }) => theme.typography.font350.fontWeight};
    font-size: 16.6667px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    color: ${({ theme }) => theme.colorTokens.gray800} !important;
  }
`;

export const UserColumnName = styled.div`
  width: 215px;
  height: 24px;

  /* Text / Bold Medium */
  font-family: ${({ theme }) => theme.typography.font350.fontFamily};
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  /* identical to box height, or 150% */

  display: flex;

  /* Obsidian */
  color: #080912;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

export const UserColumnTextWrapper = styled.div`
  width: 215px;
  height: 60px;

  padding-left: 16px;
`;


export const UserColumnNameStyled = styled.a`
  color: ${({ theme }) => theme.colors.ultramarine};
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.colors.ultramarine};
    text-decoration: underline;
  }
`;