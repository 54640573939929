// COMPANY - API
// =============================================================================
import { ParentCompanyRelationalData } from "../../containers/Settings/GeneralInfo/types";
import { apiWithErrorRedirect, apiV1WithErrorRedirect } from "./constants/index";
import {
  CompanyResponse,
  CompanyInfoWithId,
  CompanyInfo,
  GetCompanyV1Response,
  CompaniesInfoResponse,
  UsersCompaniesResponse,
  VaccinePolicies,
  CompaniesSelfResponse,
} from "./types";
import { InternalUsersResponse } from "../../containers/ManageUsers/ManageUsersTable/InternalUser";
import { ValueOf } from "../../../types/util-types";

export type GetCompanyDetailsPayload = {
  companyId: number;
};

export type UpdateCompanyDetailsPayload = {
  companyId: string | number; // Internal company id
  companyInfo: CompanyInfoWithId;
};

export const createUpdateCompanyDetailsPayload = (
  companyId: string | number,
  companyExternalId: string,
  companyInfo: CompanyInfo,
): UpdateCompanyDetailsPayload => ({
  companyId, // internal id
  companyInfo: {
    id: companyExternalId,
    ...companyInfo,
  },
});

export const updateCompanyDetails = (payload: UpdateCompanyDetailsPayload): Promise<any> => {
  // TODO:(BW-891) Type this promise, but really this will return status code and a success/fail message
  const { companyInfo } = payload;
  return apiWithErrorRedirect.patch(`v2/company/editFields`, {
    json: { companyInfo },
  });
};

export type CovidPayload = {
  covid_policy: ValueOf<typeof VaccinePolicies>;
  confirm_cancellations: Boolean;
};

export type UpdateCompanyCovidPolicyPayload = {
  covidPayload: CovidPayload;
};

// These are coming through graph-ql-workservice, which is probably translating _event_job_applied status to these strings
// Endpoint will only return assignments which have status ACCEPTED/APPLICANT/WAITLISTED:
// https://github.com/Bluecrew/api-v1/blob/c6a8c6150cb7053e6e1d6807fa887f6c13e44c86/src/server/functions/company/getAssignmentsInViolationOfCovidPolicy.js#L70
export enum AssignmentStatus {
  Accepted = "ACCEPTED",
  Applicant = "APPLICANT",
  Waitlisted = "WAITLISTED",
}

export type ConfirmCancellationsCrewMember = {
  id: string;
  external_id: string;
  first_name: string;
  last_name: string;
  shift_count: number;
  status: AssignmentStatus;
  avatar_url?: string;
};

export type UpdateCovidPolicyResponse = {
  updated: boolean;
  potential_cancellations?: Array<ConfirmCancellationsCrewMember>;
};

export type GetAllCompaniesResponse = {
  id: number;
  name: string;
  am_id?: number;
  account_manager?: string;
};

export const updateCovidPolicy = (
  payload: UpdateCompanyCovidPolicyPayload,
): Promise<UpdateCovidPolicyResponse> => {
  const { covidPayload } = payload;
  return apiV1WithErrorRedirect
    .post(`company/updateCovidPolicy`, {
      json: { ...covidPayload },
    })
    .json();
};

export const getCompanyDetails = (payload: GetCompanyDetailsPayload): Promise<CompanyResponse> => {
  const { companyId } = payload;
  return apiWithErrorRedirect.get(`v2/companies/${companyId}`).json();
};

export type SwitchCompanyPayload = {
  audience: Array<string>;
  companyId: string;
  username: string;
};

export const switchCompany = (payload: SwitchCompanyPayload) => {
  const { companyId, username } = payload;
  return apiV1WithErrorRedirect
    .post("user/addToCompany", {
      json: {
        companyId,
        username,
      },
    })
    .json();
};

// This api helper will return a list of all companies
export const getAllCompanies = () =>
  apiV1WithErrorRedirect.get("companies").json<{ companies: Array<GetAllCompaniesResponse> }>();

// This api helper will return a list of all sub-companies that belong to a parent company
export const getClientUserCompanies = (payload: any) =>
  apiV1WithErrorRedirect
    .post("company/getChildCompanies", {
      json: {
        parentCompanyId: payload.parentCompanyId,
      },
    })
    .json();

export type MyCompany = {
  companyCity: string;
  companyId: number;
  companyLogoUrl: string;
  companyName: string;
};

// Endpoint contains all logic needed to return companies for user
// If super admin, returns all companies
// If branch ops / viz returns select set
// If client admin, returns company family (parent + children)
export const getMyCompanies = (): Promise<{ result: MyCompany[] }> =>
  apiWithErrorRedirect.get("v2/users/myCompanies").json();

export const fetchCompanyUsers = (
  companyIds: number[],
  includeSignUpInfo: boolean = false,
): Promise<InternalUsersResponse> => {
  return apiWithErrorRedirect
    .post("v2/users_companies/users", {
      json: { companyIds },
      searchParams: { includeSignUpInfo },
    })
    .json();
};

export const fetchUsersCompanies = (
  userIds: number[],
  options?: {},
): Promise<UsersCompaniesResponse> => {
  const url = `v2/users_companies/companies`;
  return apiWithErrorRedirect
    .post(url, {
      json: {
        userIds,
        options,
      },
    })
    .json();
};

export const fetchUsersOwnCompanies = (options: {}): Promise<CompaniesSelfResponse> => {
  const queryParams = new URLSearchParams(options).toString();
  const url = `v2/users_companies/companiesSelf?${queryParams}`;
  return apiWithErrorRedirect.get(url).json();
};

export const getParentCompanyRelations = (
  companyId: number,
): Promise<ParentCompanyRelationalData[]> => {
  const url = `v2/companies/${companyId}/parent_company_relations`;
  return apiWithErrorRedirect.get(url).json();
};

export const getCompanyV1 = (companyId: number): Promise<GetCompanyV1Response> => {
  const url = `company/get?companyId=${companyId}`;
  return apiV1WithErrorRedirect.get(url).json();
};

export const getCompaniesInfo = (): Promise<CompaniesInfoResponse> => {
  const url = `v2/companies/info`;
  return apiWithErrorRedirect.get(url).json();
};

/**
 * @param {string} name of the company
 * @param {number} length to truncate the name to. Default is 40 characters.
 * @returns {string} name of the company truncated to characters with "..." appended
 */
export function truncateName(name: string, length: number = 35): string {
  if (name.length > length) {
    return `${name.slice(0, length)}...`;
  }
  return name;
}
