import { PayrollTimesheetResponse, TimesheetShiftDto } from "@bluecrew/finance-service-node-client";
// eslint-disable-next-line import/no-cycle
import {
  PayrollTimesheetResponseWithMessages,
  ShiftThresholds,
  TimesheetMessagesResponse,
  TimesheetsCompanyInfo,
  TimesheetsJobInfo,
  TimesheetsShiftInfo,
  TimesheetsUserInfo,
} from "../../types/Shift.types";
// eslint-disable-next-line import/no-cycle
import { convertFinanceUserShiftToLegacyUserShift } from "./UserShiftMappingUtil";

export const mapFinanceResponseToFinanceTableData = (
  response?: PayrollTimesheetResponse,
): TimesheetsShiftInfo[] => {
  return (response?.shifts ?? []).map(convertItemToTimesheetShiftInfo);
};

export const mapFinanceResponseWithMessagesToFinanceTableData = (
  response?: PayrollTimesheetResponseWithMessages,
): TimesheetsShiftInfo[] => {
  return (response?.payrollTimesheetResponse.shifts ?? [])
    .map(timesheetShift => convertShiftAndMessagingToTimesheetShiftInfo(timesheetShift, response?.timesheetMessagesResponse));
};

const convertItemToTimesheetShiftInfo = (
  timesheetShiftDto: TimesheetShiftDto,
): TimesheetsShiftInfo => {
  return {
    company: convertToCompany(timesheetShiftDto),
    job: convertToJob(timesheetShiftDto),
    shiftThresholds: convertToShiftThresholds(timesheetShiftDto),
    user: convertToUser(timesheetShiftDto),
    userShift: convertFinanceUserShiftToLegacyUserShift(timesheetShiftDto),
    userHoursId: timesheetShiftDto.usersHoursId,
  };
};

const convertShiftAndMessagingToTimesheetShiftInfo = (
  timesheetShiftDto: TimesheetShiftDto,
  timesheetMessagesResponse?: TimesheetMessagesResponse,
): TimesheetsShiftInfo => {
  const timesheetsShiftInfo: TimesheetsShiftInfo = {
    company: convertToCompany(timesheetShiftDto),
    job: convertToJob(timesheetShiftDto),
    shiftThresholds: convertToShiftThresholds(timesheetShiftDto),
    user: convertToUser(timesheetShiftDto),
    userShift: convertFinanceUserShiftToLegacyUserShift(timesheetShiftDto),
    userHoursId: timesheetShiftDto.usersHoursId,
  };

  if (timesheetsShiftInfo.company.externalId === timesheetMessagesResponse?.externalCompanyId) {
    const sentMessages = timesheetMessagesResponse.messages
      .filter((el) => el.external_job_id === timesheetsShiftInfo.job.externalId)
      .filter((el) => el.recipient_user_id === timesheetsShiftInfo.user.internalId)
      .filter((el) => {
        /**
         * Logic here is as following:
         * 1. If `shiftId` is not provided we do not filter by shiftId at all (i.e. return true from this predicate)
         * 2. If `shiftId` is a string that we compare it and `time_segment_absolute_id`
         * 3. But if `shiftId` is not string, then it could contain `timeSegmentAbsoluteId`. So in this case
         *    we compare `time_segment_absolute_id` and `shiftId.timeSegmentAbsoluteId`.
         */
        if (!timesheetShiftDto.shiftId) {
          return true;
        }
        if (typeof timesheetShiftDto.shiftId === "string") {
          return el.time_segment_absolute_id.toString() === timesheetShiftDto.shiftId;
        }
        return el.time_segment_absolute_id === timesheetShiftDto.shiftId.timeSegmentAbsoluteId;
      })
      .sort((m1, m2) => new Date(m1.sent_at).getTime() - new Date(m2.sent_at).getTime())

    if (sentMessages.length > 0) {
      const firstSentMessage = sentMessages[0];
      timesheetsShiftInfo.shiftMessageInfo = {
        messageSent: true,
        recipientUserName: firstSentMessage.recipient_user_first_name,
        senderUserName: firstSentMessage.sender_user_first_name,
        messageDate: firstSentMessage.sent_at
      };
    }
  }

  return timesheetsShiftInfo;
};

const convertToCompany = (timesheetShiftDto: TimesheetShiftDto): TimesheetsCompanyInfo => {
  return {
    externalId: timesheetShiftDto.company.externalId,
    name: timesheetShiftDto.company.name,
  };
};

const convertToJob = (timesheetShiftDto: TimesheetShiftDto): TimesheetsJobInfo => {
  const unconvertedJobInfo = timesheetShiftDto.job;
  return {
    externalId: unconvertedJobInfo.externalId,
    name: unconvertedJobInfo.name,
    start: new Date(unconvertedJobInfo.start),
    end: new Date(unconvertedJobInfo.end),
    wage: unconvertedJobInfo.wage.amount,
    timezone: unconvertedJobInfo.timezone,
    shiftIndex: unconvertedJobInfo.shiftIndex,
    address: unconvertedJobInfo.address,
    coordinates: {
      lat: unconvertedJobInfo.coordinates.latitude,
      lng: unconvertedJobInfo.coordinates.longitude,
    },
    supervisorName: unconvertedJobInfo.supervisor?.name,
  };
};

const convertToShiftThresholds = (timesheetShiftDto: TimesheetShiftDto): ShiftThresholds => {
  const unconvertedShiftThresholds = timesheetShiftDto.shiftThresholds;
  return {
    warnRadius: unconvertedShiftThresholds.warnRadius ?? null,
    blockRadius: unconvertedShiftThresholds.blockRadius ?? null,
    earlyClockinBuffer: unconvertedShiftThresholds.earlyClockinBuffer,
    lateClockoutBuffer: unconvertedShiftThresholds.lateClockoutBuffer,
    minLunchDurationHours: unconvertedShiftThresholds.minLunchDurationHours,
    expectedStart: new Date(timesheetShiftDto.job.start),
    expectedEnd: new Date(timesheetShiftDto.job.end),
  };
};

const convertToUser = (timesheetShiftDto: TimesheetShiftDto): TimesheetsUserInfo => {
  const unconvertedUser = timesheetShiftDto.user;
  return {
    externalId: unconvertedUser.externalId,
    firstName: unconvertedUser.firstName,
    lastName: unconvertedUser.lastName,
    profilePic: !unconvertedUser.profilePic ? undefined : new URL(unconvertedUser.profilePic),
    internalId: unconvertedUser.internalId,
  };
};
