// =============================================================================
// Presentational component that renders when a position table has no schedules yet
// This component may be no longer be needed if all positions are required to be created with a schedule
// if that is the case, delete.

import React from "react";
import { Block, Button, Image, Paragraph } from "@bluecrew/web-react-core";
import { palette as p } from "styled-tools";
import styled from "styled-components";
import { IconHeader } from "../../components/IconHeader";
import { Can } from "../PermissionsContext";
import { colorTheme } from "@bluecrew/blueprint-web";

const PositionWithNoSchedulesContent = styled(Block)`
  text-align: center;

  // Style components rendered in <IconHeader>
  ${Image} {
    background: ${p("white")}; //overrides default background color for icon
    display: inline-flex;
    max-width: unset;
    height: 4rem;
    width: 4rem;
  }

  ${Paragraph} {
    margin: 1rem 0 1.5rem 0;
    color: ${p("platinum")};
    font-size: 1rem;
    line-height: 1.5rem;
  }
`;

const StyledButtonContainer = styled.div`
  display: block;
  ${Button} {
    display: inline;
    margin: 0 0.5rem;
  }
  ${Paragraph} {
    display: inline;
    margin: 0 0.5rem;
  }
`;

const ArchiveButton = styled(Button)`
  height: 28px;
  text-align: center;
  padding: 6px 8px;
  background-color: ${colorTheme.dashboardSecondaryColor};
  color: ${colorTheme.dashboardMainColor};
  border: 1px solid ${colorTheme.dashboardMainColor};

  :hover {
    cursor: pointer;
    background: ${colorTheme.dashboardMainColor};
    color: ${colorTheme.dashboardSecondaryColor};
  }
`;

type PositionWithNoSchedulesProps = {
  handleArchivePositionClick: () => void;
};

export const PositionWithNoSchedules = ({
  handleArchivePositionClick,
}: PositionWithNoSchedulesProps) => {
  return (
    <PositionWithNoSchedulesContent>
      <IconHeader text="This position has no active schedules" icon="/icons/position-search.svg" />
      <Can I="delete" a="position">
        <StyledButtonContainer>
          <ArchiveButton onClick={handleArchivePositionClick} palette={"primary"}>
            Delete this position
          </ArchiveButton>
        </StyledButtonContainer>
      </Can>
    </PositionWithNoSchedulesContent>
  );
};
