import React from "react";
import {
  WageColumnCellWrapper,
  WageColumnCellHoursWrapper,
  WageColumnCellRateWrapper,
} from "./styledComponents";
import { calculateShiftDuration } from "../../payrollDataProcessors";
import { Can } from "../../../PermissionsContext";
import { TimesheetsShiftInfo } from "../../types/Shift.types";

export type WageColumnCellProps = {
  rowData: TimesheetsShiftInfo;
};

export const WageColumnCell = ({ rowData }: WageColumnCellProps) => {
  const shiftDuration = calculateShiftDuration(rowData.userShift);
  return (
    <WageColumnCellWrapper>
      <WageColumnCellHoursWrapper>
        {shiftDuration.hours}h {shiftDuration.minutes}m
      </WageColumnCellHoursWrapper>
      <Can I="view" a="wages">
        <WageColumnCellRateWrapper>@ ${rowData.job.wage}/hr</WageColumnCellRateWrapper>
      </Can>
    </WageColumnCellWrapper>
  );
};
