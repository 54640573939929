import React, { useMemo } from "react";
import { Checkbox } from "primereact/checkbox";
import { CompanyWorker } from "../../../../../api/bluecrew/payroll";
import { WorkerListContainer, WorkerListItemContainer } from "../styledComponents";
import { WorkerInfo } from "./WorkerInfo";

export type WorkerListProps = {
  allWorkers: Array<CompanyWorker>;
  selectedWorkers: Map<string, CompanyWorker>;
  setSelectedWorkers: (workers: Map<string, CompanyWorker>) => void;
  filterText: string;
};
export const WorkerList = ({
  allWorkers,
  selectedWorkers,
  setSelectedWorkers,
  filterText,
}: WorkerListProps) => {
  const handleListItemClick = (worker: CompanyWorker) => {
    const newSelectedWorkers = new Map<string, CompanyWorker>();
    selectedWorkers.forEach(selected => newSelectedWorkers.set(selected.externalId, selected));
    if (selectedWorkers.has(worker.externalId)) {
      newSelectedWorkers.delete(worker.externalId);
    } else {
      newSelectedWorkers.set(worker.externalId, worker);
    }
    setSelectedWorkers(newSelectedWorkers);
  };

  /* eslint-disable no-param-reassign */
  const workerListLower = useMemo(() => allWorkers.map(worker => {
    worker.lastName = worker.lastName.toLowerCase();
    worker.firstName = worker.firstName.toLowerCase();
    return worker;
  }), [allWorkers]);
  /* eslint-enable no-param-reassign */

  const filteredWorkers = useMemo(() => {
    const filterTextLower = filterText.toLowerCase();
    return filterText.length ? workerListLower
      .filter((worker) => `${worker.firstName} ${worker.lastName}`
        .includes(filterTextLower))
      : allWorkers;
  }, [filterText, workerListLower]);

  return (
    <WorkerListContainer>
      {filteredWorkers.map((worker) => (
        <WorkerListItemContainer
          key={worker.externalId}
          onClick={() => handleListItemClick(worker)}
        >
          <WorkerInfo worker={worker} />
          <Checkbox checked={selectedWorkers.has(worker.externalId)} />
        </WorkerListItemContainer>
      ))}
    </WorkerListContainer>
  );
};
