import { useStep } from "react-hooks-helper";
import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Toast } from "primereact/toast";
import { FooterButton, StyledAddShiftModal, StyledButtonContainer } from "./styledComponents";
import { CreateShiftScreen } from "./Screens/CreateShiftScreen";
import { SelectWorkersScreen } from "./Screens/SelectWorkersScreen";
import { AddShiftFormTypes, Position } from "./AddShiftForm.types";
import { useAddShiftMutation, useGetAllWorkersQuery } from "../../../../api/bluecrew/hooks/payroll";
import { CompanyWorker } from "../../../../api/bluecrew/payroll";
import { ConfirmWorkersScreen } from "./Screens/ConfirmWorkersScreen";
import { ErrorText } from "../../styledComponents";
import { getCreateNextShiftErrorMessage, getSelectWorkersErrorMessage } from "./addShiftUtils";

enum AddShiftStep {
  CREATE_SHIFT,
  SELECT_WORKERS,
  CONFIRM_WORKERS,
}

export type AddShiftModalProps = {
  isModalOpen: boolean;
  setModalOpen: (modalOpen: boolean) => void;
};
export const AddShiftModal = ({ isModalOpen, setModalOpen }: AddShiftModalProps) => {
  const companyId = useParams().internalCompanyId!!;
  const [selectedWorkers, setSelectedWorkers] = useState<Map<string, CompanyWorker>>(new Map());
  const [formState, setFormState] = useState<AddShiftFormTypes>({});
  const [currentPosition, setCurrentPosition] = useState<Position>();
  const [error, setError] = useState<string>();

  const numSteps = 3;
  const {
    index: step,
    navigation: { go },
  } = useStep({
    steps: numSteps,
    initialStep: AddShiftStep.CREATE_SHIFT,
  });

  const { data: workers, isLoading: areWorkersFetching } = useGetAllWorkersQuery(companyId);

  const resetModal = () => {
    setModalOpen(false);
    setFormState({});
    setSelectedWorkers(new Map());
    go(AddShiftStep.CREATE_SHIFT);
  };

  const { mutate: sendAddShiftRequest } = useAddShiftMutation(
    () => {
      showSuccessToast();
    },
    (message: string) => {
      showErrorToast(message);
    },
  );

  const convertTZ = (timezoneString: string, date?: Date) => {
    if (date === undefined) {
      return undefined;
    }
    const newDate = new Date(
      date.toLocaleString("en-US", {
        timeZone: timezoneString,
      }),
    );
    const newDateMilli = newDate.valueOf();
    const dateMilli = date.valueOf();
    const difference = dateMilli - newDateMilli;
    return new Date(dateMilli + difference);
  };

  function formatTimestampsForPositionTimezone(
    currentformState: AddShiftFormTypes,
    position?: Position,
  ) {
    if (position === undefined) {
      return undefined;
    }
    const { timezone } = position;
    const { clockIn, clockOut } = currentformState;
    const zonedClockIn = convertTZ(timezone, clockIn);
    const zonedClockOut = convertTZ(timezone, clockOut);
    return {
      ...currentformState,
      clockIn: zonedClockIn,
      clockOut: zonedClockOut,
    };
  }

  const getHeaderTextFromStep = () => {
    switch (step) {
      case AddShiftStep.CREATE_SHIFT:
        return "Create a new shift";
      case AddShiftStep.SELECT_WORKERS:
        return "Select workers";
      case AddShiftStep.CONFIRM_WORKERS:
        return `Confirm adding ${selectedWorkers.size} Crew Member(s)`;
      default:
        throw Error("Invalid Step");
    }
  };

  const getFooterTextFromStep = () => {
    switch (step) {
      case AddShiftStep.CREATE_SHIFT:
        return "Select workers";
      case AddShiftStep.SELECT_WORKERS:
        return "Confirm shift";
      case AddShiftStep.CONFIRM_WORKERS:
        return "Add to shift";
      default:
        throw Error("Invalid Step");
    }
  };

  const toast = useRef<Toast>(null);
  const showSuccessToast = () => {
    toast?.current?.show({
      severity: "success",
      summary: "Success",
      detail: "Added Crew Member(s) to shift",
    });
  };
  const showErrorToast = (message: string) => {
    toast?.current?.show({ severity: "error", summary: "Error", detail: message });
  };

  const nextHandler = () => {
    if (step === AddShiftStep.CREATE_SHIFT) {
      createShiftNextHandler();
    } else if (step === AddShiftStep.SELECT_WORKERS) {
      confirmWorkersNextHandler();
    } else if (step === AddShiftStep.CONFIRM_WORKERS) {
      const zonedFormState = formatTimestampsForPositionTimezone(formState, currentPosition);
      const reqWorkers = Array.from(selectedWorkers.values());
      const request = {
        ...zonedFormState,
        workers: [...reqWorkers],
      };
      sendAddShiftRequest(request);
      resetModal();
    }
  };

  const createShiftNextHandler = () => {
    const error = getCreateNextShiftErrorMessage(formState);
    if (!error) {
      go(AddShiftStep.SELECT_WORKERS);
    }
    setError(error);
  };

  const confirmWorkersNextHandler = () => {
    const error = getSelectWorkersErrorMessage(selectedWorkers);
    if (!error) {
      go(AddShiftStep.CONFIRM_WORKERS);
    }
    setError(error);
  };

  return (
    <>
      <Toast ref={toast} />
      <StyledAddShiftModal
        data-testid={"add-shift-modal"}
        header={getHeaderTextFromStep()}
        visible={isModalOpen}
        onHide={() => resetModal()}
        footer={
          <StyledButtonContainer>
            <FooterButton disabled={areWorkersFetching} onClick={nextHandler}>
              {getFooterTextFromStep()}
            </FooterButton>
          </StyledButtonContainer>
        }
      >
        <>
          {step === AddShiftStep.CREATE_SHIFT && (
            <CreateShiftScreen
              formValues={formState}
              setFormValues={setFormState}
              setCurrentPosition={setCurrentPosition}
            />
          )}
          {step === AddShiftStep.SELECT_WORKERS && (
            <SelectWorkersScreen
              allWorkers={workers || []}
              selectedWorkers={selectedWorkers}
              setSelectedWorkers={setSelectedWorkers}
            />
          )}
          {step === AddShiftStep.CONFIRM_WORKERS && (
            <ConfirmWorkersScreen selectedWorkers={selectedWorkers} />
          )}
          {error && <ErrorText>{error}</ErrorText>}
        </>
      </StyledAddShiftModal>
    </>
  );
};
