import React from "react";
import { Column } from "react-table";
import { WageColumnCell } from "./WageColumnCell";
import { ActionColumnCell } from "./ActionColumnCell";
import { ReasonColumnCell } from "./ReasonColumnCell";
import { TimesheetsShiftInfo } from "../../types/Shift.types";
import { UserCellWrapper } from "./UserColumnCell/styledComponents";
import { TimesheetTabs } from "../../types/Payroll.types";
import { TimestampColumnContainer } from "./TimeColumnCell/TimestampColumnContainer";
import { UserColumnCell } from "./UserColumnCell/UserColumnCell";

type TimesheetsTableRowDataType = {
  rowData: TimesheetsShiftInfo;
  activeTabIndex: TimesheetTabs;
  rowHoverState: boolean;
};

export const TableColumns: Column<TimesheetsShiftInfo>[] = [
  {
    id: "userColumn",
    className: "user-column",
    Cell: ({ rowData }: TimesheetsTableRowDataType) => (
      <UserCellWrapper>
        <UserColumnCell
          firstName={rowData.user.firstName}
          lastName={rowData.user.lastName}
          jobInfo={rowData.job}
          profilePic={rowData.user.profilePic}
          shiftMessageInfo={rowData.shiftMessageInfo}
          externalId={rowData.user.externalId}
        />
      </UserCellWrapper>
    ),
  },
  {
    id: "timeColumn",
    className: "time-column",
    Cell: ({ rowData, activeTabIndex }: TimesheetsTableRowDataType) =>
      [TimesheetTabs.PENDING_REVIEW, TimesheetTabs.APPROVED, TimesheetTabs.DISPUTED].includes(
        activeTabIndex,
      ) && <TimestampColumnContainer rowData={rowData} />,
  },
  {
    id: "reasonColumn",
    className: "reason-column",
    Cell: ({ rowData, activeTabIndex }: TimesheetsTableRowDataType) => (
      <>
        {[TimesheetTabs.DISPUTED, TimesheetTabs.REMOVED].includes(activeTabIndex) &&
          rowData.userShift.statusReason !== undefined && (
          <ReasonColumnCell
            rowData={rowData}
            activeTabIndex={activeTabIndex}
          />
        )}
      </>
    ),
  },
  {
    id: "wageColumn",
    className: "wage-column",
    Cell: ({ rowData }: TimesheetsTableRowDataType) => (
      <>
        <WageColumnCell rowData={rowData} />
      </>
    ),
  },
  {
    id: "actionsColumn",
    className: "actions-column",
    Cell: ({ rowData, activeTabIndex }: TimesheetsTableRowDataType) => (
      <ActionColumnCell rowData={rowData} activeIndex={activeTabIndex} />
    ),
  },
];

