import React from "react";
import { isValid } from "date-fns";
import {
  ShiftAdjustBodyText,
  ShiftAdjustTimeEditingContainer,
  ShiftAdjustDatePicker,
} from "./styledComponents";
import { TimePickerWithKeyboardInput } from "./TimePickerWithKeyboardInput";

export type ShiftAdjustTimePickerProps = {
  label: string;
  setDate: (d: Date) => void;
  date?: Date;
  formattedTimezone?: string;
};

export const ShiftAdjustTimePicker = ({
  label,
  date,
  setDate,
  formattedTimezone,
}: ShiftAdjustTimePickerProps) => {
  return (
    <ShiftAdjustTimeEditingContainer>
      <ShiftAdjustBodyText>
        {label} {formattedTimezone}
      </ShiftAdjustBodyText>
      <TimePickerWithKeyboardInput
        date={date}
        setDate={setDate}
      />
      <ShiftAdjustDatePicker
        value={date}
        onChange={(d) => isValid(d.value) && setDate(d.value as Date)}
        showTime
        readOnlyInput
      />
    </ShiftAdjustTimeEditingContainer>
  );
};
