import { apiV1WithErrorRedirect } from "./constants";
// eslint-disable-next-line import/named,import/no-cycle
import { TimesheetMessagesResponse } from "../../containers/Payroll/types/Shift.types";

export type TimesheetMessagePayload = {
  externalCompanyId: string;
  week: number;
  externalJobId: string;
  timeSegmentAbsoluteId: number;
};

export type IntercomGroupPayload = {
  userIds: string[];
  messages: string[];
  fromCompany: boolean;
  timesheetMessages?: TimesheetMessagePayload[];
};

export const intercomGroup = (payload: IntercomGroupPayload) => {
  return apiV1WithErrorRedirect
    .post("intercom/group", {
      json: payload,
    })
    .json();
};

export const getTimesheetMessages = (companyId: string, week: number): Promise<TimesheetMessagesResponse> => {
  return apiV1WithErrorRedirect
    .get(`intercom/timesheetMessages?externalCompanyId=${companyId}&week=${week}`)
    .json<TimesheetMessagesResponse>();
};
