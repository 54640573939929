import React from "react";
import { CompanyWorker } from "../../../../../api/bluecrew/payroll";
import { WorkerListContainer, WorkerListItemContainer } from "../styledComponents";
import { WorkerInfo } from "../Components/WorkerInfo";

export type ConfirmWorkersScreenProps = {
  selectedWorkers: Map<string, CompanyWorker>;
};
export const ConfirmWorkersScreen = ({ selectedWorkers }: ConfirmWorkersScreenProps) => {
  const workers = Array.from(selectedWorkers.values());

  return (
    <WorkerListContainer>
      {workers.map((worker) => (
        <WorkerListItemContainer key={worker.externalId}>
          <WorkerInfo worker={worker} />
        </WorkerListItemContainer>
      ))}
    </WorkerListContainer>
  )};
