import React, { useRef, useState } from "react";
import { useIsMutating } from "@tanstack/react-query";
import { ActionColumnButtonWrapper } from "../styledComponents";
import { RemoveModal } from "../ShiftReject/RemoveModal";
import {
  getPayrollActionKeyPrefix,
  useRejectShiftMutation,
} from "../../../../../api/bluecrew/hooks/payroll";
import { PayrollStatusReason } from "../../../types/Payroll.types";
import { ActionButton, ActionButtonIcon } from "./styledComponents";
import { Toast } from "primereact/toast";
import { ActionButtonIconPathPrefix } from "./index";
import { buildShiftKey } from "../../../shiftUtils";
import { Tooltip } from "../../../../../components/Tooltip";
import { TimesheetsShiftInfo } from "../../../types/Shift.types";

export type ButtonRejectProps = {
  disabled: boolean;
  rowData: TimesheetsShiftInfo;
};

export const ButtonReject = ({ disabled, rowData }: ButtonRejectProps) => {
  const [isRejectVisible, setIsRejectVisible] = useState<boolean>(false);

  const toast = useRef<Toast>(null);
  const showErrorToast = (message: string) => {
    toast?.current?.show({ severity: "error", summary: "Error", detail: message });
  };

  const { user, job } = rowData;

  const adjustmentCodes = [
    PayrollStatusReason.NO_CALL_NO_SHOW,
    PayrollStatusReason.TIME_THEFT,
    PayrollStatusReason.NO_LONGER_NEEDED,
    PayrollStatusReason.UNQUALIFIED,
    PayrollStatusReason.RESIGNED,
  ];

  const shiftKey = buildShiftKey(rowData);
  const tooltipAnchor = `reject-${shiftKey.cmId}-${shiftKey.jobId}-${shiftKey.shiftIndex}`;
  const { mutate } = useRejectShiftMutation(shiftKey, showErrorToast);
  const isAnyPayrollActionMutating = !!useIsMutating(getPayrollActionKeyPrefix(shiftKey));

  return (
    <ActionColumnButtonWrapper>
      <Toast ref={toast} />
      <Tooltip position={"top"} target={`.${tooltipAnchor}`}>
        {"Reject"}
      </Tooltip>
      <ActionButton
        className={tooltipAnchor}
        disabled={disabled || isAnyPayrollActionMutating}
        icon={<ActionButtonIcon src={`${ActionButtonIconPathPrefix}/reject.svg`} />}
        rounded
        outlined
        onClick={() => {
          setIsRejectVisible(true);
        }}
      />
      <RemoveModal
        isRemoveModalVisible={isRejectVisible}
        setIsRemoveModalVisible={setIsRejectVisible}
        headerText={"Reject Timestamps"}
        footerButtonLabel={"Reject"}
        shiftInfo={rowData}
        adjustmentCodes={adjustmentCodes}
        removeShiftHandler={(reason: PayrollStatusReason) =>
          mutate({
            userId: user.externalId,
            jobId: job.externalId,
            shiftIndex: job.shiftIndex,
            reason,
          })
        }
      />
    </ActionColumnButtonWrapper>
  );
};
