import React, { useRef, useState } from "react";
import { useIsMutating } from "@tanstack/react-query";
import { ActionColumnButtonWrapper } from "../styledComponents";
import { ShiftAdjustment, ShiftAdjustModal } from "../ShiftAdjust/ShiftAdjustModal";
import { PayrollStatusReason } from "../../../types/Payroll.types";
import { TimesheetsShiftInfo } from "../../../types/Shift.types";
import { getExternalUserId } from "../../../../../redux/selectors/auth";
import {
  getPayrollActionKeyPrefix,
  useDisputeShiftMutation,
} from "../../../../../api/bluecrew/hooks/payroll";
import {
  buildShiftKey,
  buildShiftTimes,
  getDisputeReasonCodesWithShiftAdjustment,
} from "../../../shiftUtils";
import { ActionButton, ActionButtonIcon } from "./styledComponents";
import { Toast } from "primereact/toast";
import { ActionButtonIconPathPrefix } from "./index";
import { Tooltip } from "../../../../../components/Tooltip";
import { useAppSelector } from "../../../../../redux";

export type ButtonFlagProps = {
  rowData: TimesheetsShiftInfo;
  disabled?: boolean;
};

export const ButtonFlag = ({ rowData, disabled }: ButtonFlagProps) => {
  const [isDisputeVisible, setIsDisputeVisible] = useState<boolean>(false);

  const toast = useRef<Toast>(null);
  const showErrorToast = (message: string) => {
    toast?.current?.show({ severity: "error", summary: "Error", detail: message });
  };

  const wpUserId = useAppSelector(getExternalUserId);

  const headerProps = {
    headerText: "Dispute Timesheet",
    subHeaderText: "All disputes are reviewed by the Crew Member and Bluecrew Support.",
  };
  const footerProps = {
    footerButtonLabel: "Dispute",
  };
  const adjustmentCodes = [
    PayrollStatusReason.SUPPORT_UNADJUSTED,
    PayrollStatusReason.RECEIVED_BREAKS,
    PayrollStatusReason.NO_CALL_NO_SHOW,
    PayrollStatusReason.TIME_THEFT,
    PayrollStatusReason.WALK_OFF,
    PayrollStatusReason.NO_LONGER_NEEDED,
    PayrollStatusReason.UNQUALIFIED_HOURS,
    PayrollStatusReason.UNQUALIFIED,
  ];
  const adjustmentCodesWithTimeEdit = getDisputeReasonCodesWithShiftAdjustment();
  const { user, job, userShift } = rowData;

  const disputeShiftRequest = {
    userId: user.externalId,
    jobId: job.externalId,
    shiftIndex: job.shiftIndex,
    userShift: userShift,
    shiftInfo: rowData,
  };

  const shiftKey = buildShiftKey(rowData);
  const tooltipAnchor = `excuse-${shiftKey.cmId}-${shiftKey.jobId}-${shiftKey.shiftIndex}`;
  const { mutate } = useDisputeShiftMutation(shiftKey, showErrorToast);
  const isAnyPayrollActionMutating = !!useIsMutating(getPayrollActionKeyPrefix(shiftKey));
  const flagShiftHandler = (adjustment: ShiftAdjustment) =>
    mutate({
      ...disputeShiftRequest,
      wpUserId,
      disputeInfo: adjustment,
    });

  return (
    <ActionColumnButtonWrapper>
      <Toast ref={toast} />
      <Tooltip position={"top"} target={`.${tooltipAnchor}`}>
        {"Flag"}
      </Tooltip>
      <ActionButton
        className={tooltipAnchor}
        disabled={disabled || isAnyPayrollActionMutating}
        icon={<ActionButtonIcon src={`${ActionButtonIconPathPrefix}/flag.svg`} />}
        rounded
        outlined
        onClick={() => {
          setIsDisputeVisible(true);
        }}
      />
      <ShiftAdjustModal
        headerProps={headerProps}
        footerProps={footerProps}
        shiftInfo={rowData}
        adjustmentCodes={adjustmentCodes}
        adjustmentCodesWithTimeEdit={adjustmentCodesWithTimeEdit}
        shiftAdjustModalVisible={isDisputeVisible}
        setShiftAdjustModalVisible={setIsDisputeVisible}
        shiftAdjustmentHandler={flagShiftHandler}
        defaultShiftTimes={buildShiftTimes(rowData)}
      />
    </ActionColumnButtonWrapper>
  );
};
