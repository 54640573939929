import { differenceInCalendarWeeks } from "date-fns";
import {apiV1WithErrorRedirect, apiWithErrorRedirect, financeApi, financeApiPlainTextResponse} from "./constants";
import { SiteDesignation } from "../../containers/GetCrewMembers/types/propTypes/CreatePositionScreen.types";
import { JobType } from "../../containers/GetCrewMembers/CreatePositionScreen/PositionDetailsSection/JobType";
import {
  MarkupType,
  PayrollAutoApprovalStatus,
  PayrollHoursStatus,
  PayrollStatusReason,
  TimestampType,
} from "../../containers/Payroll/types/Payroll.types";
import { UserType } from "./types";

const RETRY_LIMIT = 3;

export type PayrollReportDataType = {
  accountManagerId: number | null;
  accountManagerName: string | null;
  address: {
    city: string | null;
    state: string | null;
    address: string | null;
    zipcode: string | null;
  };
  externalId: string;
  id: number;
  name: string;
  needApprovalAllTime: number;
  region_id: number;
  totalThisWeek: number;
  pendingReview: number;
  disputed: number;
  overdue: number;
};

export type PayrollReportsResponseType = Array<PayrollReportDataType>;

export type UserTimestamp = {
  id: string;
  time: Date;
  type: TimestampType;
  distance: number | null;
  latitude: number | null;
  longitude: number | null;
  isUserEdited: boolean; // Comes in as int, exists as (0, 1)
  noPhoneClock: boolean; // Comes in as int, exists as (0, 1)
  createdAt: Date;
  updatedAt: Date;
};

export type UserHoursInfo = {
  id: string;
  hoursId?: number | null;
  hoursCompleted?: boolean | null;
  hoursStart?: Date | null;
  hoursEnd?: Date | null;
  hoursStatus?: string | null;
  status?: PayrollHoursStatus | null; // same as hoursStatus, but in int..
  note?: string | null;
  reason?: PayrollStatusReason | null;
  lunchDuration?: number;
  autoApprovalDetermination?: PayrollAutoApprovalStatus | null;
  autoApprovalTimestamp?: Date | null;
  manuallyAddedBy?: string | null;
  manuallyAddedByFirstName?: string | null;
  manuallyAddedByLastName?: string | null;
  markedLate?: number | null;
  markedLateByName?: string | null;
  markedLateByFirstName?: string | null;
  markedLateByLastName?: string | null;
  crewMemberConfirmed?: number;
  crewMemberConfirmedAt?: Date | null;
  createdAt: Date;
  createdByName: string;
  createdByFirstName: string;
  createdByLastName: string;
  createdByPermission: UserType;
  updatedAt?: Date | null;
  updatedByExternalId?: string | null;
  updatedByName?: string | null;
  updatedByFirstName?: string | null;
  updatedByLastName?: string | null;
  updatedByPermission?: UserType | null;
  timestamps?: Array<UserTimestamp>;
  sentPayroll: boolean;
  sentInvoice: boolean;
  isUpdatedBySupport: boolean;
};

export type JobShiftPool = {
  id: string;
  start: Date;
  end: Date;
  internalId: number;
  job_type: JobType;
  min_block_radius: number | null;
  min_warn_radius: number | null;
  shift: number;
  shiftEnded: boolean; // Comes in as int, exists as (0, 1)
  site_designation: SiteDesignation;
  supervisorName: string | null;
  supervisorFirstName: string | null;
  supervisorLastName: string | null;
  users: Array<UserHoursInfo>;
};

export type CompanyShiftPool = {
  date: Date;
  jobs: Array<JobShiftPool>;
  remaining: number;
  total: number;
};

export type CompanyShifts = {
  id: string;
  dates: Array<CompanyShiftPool>;
};

export type CompanyInfo = {
  name: string;
  logoURL: URL;
  markup: number;
  markupType: MarkupType;
  minLunchDuration: number;
  minOffsiteRadius: number;
  earlyClockinBuffer: number;
  lateClockoutBuffer: number;
  lateTolerance: number | null;
  lateLimit: number | null;
  absenceLimit: number | null;
  terminalMode: boolean; // Comes in as int, exists as (0, 1)
};

export type JobInfo = {
  title: string;
  wage: number;
  timezone: string;
  address: string;
  latitude: number;
  longitude: number;
};

export type UserInfoNotes = {
  undefined: Array<string>;
};

export type UserInfo = {
  id: number;
  firstName: string | null;
  lastName: string | null;
  phone_number: string | null;
  profilePictureURL: URL | null;
  vensureId: string | null;
  notes: UserInfoNotes | null;
};

type ExternalCompanyId = string;
type ExternalJobId = string;
type ExternalUserId = string;

export type CompanyPayrollResponseType = {
  companies?: Record<ExternalCompanyId, CompanyInfo>;
  payroll: {
    companies?: Array<CompanyShifts>;
  };
  jobs?: Record<ExternalJobId, JobInfo>;
  users?: Record<ExternalUserId, UserInfo>;
};

// TODO(@dchhina): Try to get rid of this all-together.
// It looks like the legacy system used to have a week cut-off at Mon 2am (along with the weird offset)
// We are using Mon mid-night as cutoff. This should not have much material affect
export const getPayrollWeek = (date = new Date()) =>
  differenceInCalendarWeeks(date, new Date(1970, 0, 1), { weekStartsOn: 1 }) - 1;

export type PayrollHours = {
  users_hour_id: number;
  hours_id: number;
  week: number;
  user_id: number;
  user_external_id: string;
  first_name: string | null;
  last_name: string | null;
  name: string;
  shift: number;
  shift_end: Date | null;
  shift_start: Date | null;
  lunch_duration: number;
  total_hours: number;
  regular_hours: number;
  wage: number;
  wage_client: number;
  wages_comped: false;
  worker_gross: number;
  worker_gross_client: number;
  doubletime_hours: number;
  doubletime_start: number;
  dt_bill_rate: number | null;
  bonus_wage: number | null;
  net_pay: number;
  ot_bill_rate: number | null;
  overtime_hours: 0;
  overtime_start: number;
  surge_wage: number;
  job_id: number;
  job_external_id: string;
  job_type: JobType;
  title: string;
  address_id: number;
  company_internal_id: number;
  company_id: string;
  company_name: string;
  comp_code: string | null;
  department: string | null;
  department_id: number | null;
  department_tag: string | null;
  quickbooks_id: number; // 0 if there's no ID
  bill_rate: number | null;
  billing_email: string;
  child_descriptor: string | null;
  region_name: string | null;
  state: string;
  timezone: string; // TODO: Check if this can be directly parsed into a better DS. Comes in as TZ identifier.
  cost: number;
  cost_client: number;
  cost_per_hour: number;
  cost_per_hour_client: number;
  cost_per_hour_doubletime: number;
  cost_per_hour_doubletime_client: number;
  cost_per_hour_overtime: number;
  cost_per_hour_overtime_client: number;
  group_cost: number;
  group_cost_client: number;
  group_doubletime_hours: number;
  group_overtime_hours: number;
  group_regular_hours: number;
  group_total_hours: number;
  group_worker_gross: number;
  group_worker_gross_client: number;
  insurance: number;
  vensure_id: number | null;
  is_hosted: boolean; // Comes in as (0, 1)
  date: string; // Comes in as %a %c/%d/%y
  address: string;
  zipcode: string;
  position_id: number;
  markup: number;
  pricing_type: number;
  project_number: string | null;
  service_fee: number;
  service_fee_comped: boolean;
  approved: boolean;
  sent_invoice: boolean;
  sent_payroll: boolean;
  auto_approval_determination: PayrollAutoApprovalStatus | null;
  auto_approval_timestamp: Date | null;
  created_by_user_id: number;
};

export type PayrollHoursResponseType = Array<PayrollHours>;

export const getPayrollReportsRequest = (date = new Date()): Promise<PayrollReportsResponseType> => {
  const currentPayrollWeek = getPayrollWeek(date);

  return apiV1WithErrorRedirect.get(`payroll/payroll-report?week=${currentPayrollWeek}`).json();
};

export const postCompanyPayrollRequestV1 = (
  companyId: string,
  week = getPayrollWeek(),
): Promise<CompanyPayrollResponseType> =>
  apiV1WithErrorRedirect
    .post(`company/payroll`, {
      json: {
        companyId,
        week,
      },
    })
    .json();

export const postPayrollHoursRequest = (
  companyId: string,
  week = getPayrollWeek(),
): Promise<PayrollHoursResponseType> =>
  apiV1WithErrorRedirect
    .post(`payroll/hours`, {
      json: {
        companyId,
        week,
      },
    })
    .json();

export type GetCompanyPositionsResponseEntry = {
  id: number;
  title: string;
  wage_floor: number;
  timezone: string;
};

export type GetCompanyPositionsResponse = {
  positions: Array<GetCompanyPositionsResponseEntry>;
};
export const getCompanyPositions = (companyId: string): Promise<GetCompanyPositionsResponse> =>
  apiV1WithErrorRedirect.get(`companies/${companyId}/positions`).json();

export type CompanyWorker = {
  externalId: string;
  firstName: string;
  lastName: string;
  email: string;
  companyShiftsCount: number;
  platformShiftsCount: number;
  neutralShiftsCount: number;
  negativeShiftsCount: number;
  phoneNumber?: string;
  profilePicSrc?: URL;
  lastShift?: Date;
};

export type GetAllWorkersResponseEntry = {
  external_id: string;
  firstName: string;
  lastName: string;
  username: string;
  neutral_count: number;
  negative_count: number;
  shiftsWorked?: number;
  total_shift_count?: number;
  phoneNumber?: string;
  profilePic?: URL;
  lastShift?: Date;
};

// TODO(@dchhina): This does not scale. This call currently responds with an eagerly-loaded list of all workers on the
//  platform and then joins on _event* tables to find all jobs applied, shifts worked etc for the company. As we scale and
//  the platform gets old the latency here increases along with the response and view size.
//  We Need to change this flow. Maybe start with some basic filtering and pagination.
export const getAllWorkers = (companyId: string): Promise<Array<GetAllWorkersResponseEntry>> =>
  apiV1WithErrorRedirect.get(`companies/${companyId}/workers`).json();

export type CreateRawJobPayload = {
  position_id: number;
  schedule_name: string;
  schedule_type: number;
  start_date_time: string;
  end_date_time: string;
  work_days: string[];
  base_wage: string;
  workers_needed: number;
  supervisor_user_id: number;
  is_application: number;
};

export type CreateRawJobResponse = {
  jobId: number;
};

export const createRawJob = (createRawJobPayload: CreateRawJobPayload) =>
  apiWithErrorRedirect
    .post("v2/jobs/raw", {
      json: createRawJobPayload,
      retry: { limit: RETRY_LIMIT },
    })
    .json<CreateRawJobResponse>();

export type PutOnJobPayload = {
  userIds: Array<string>;
  jobId: number;
  approveHours: boolean;
  lunchHours: string;
};

export const putOnJob = (putOnJobPayload: PutOnJobPayload) =>
  apiV1WithErrorRedirect.post("user/putOnJob", {
    json: putOnJobPayload,
    retry: { limit: RETRY_LIMIT },
  });

export type ApproveShiftPayload = {
  userId: string;
  jobId: string;
  shift: number;
  shiftStart: string;
  shiftEnd: string;
  lunchDuration: string; // lunch duration in hours
  reason?: number;
};

export const approveShift = (payload: ApproveShiftPayload) =>
  financeApi.post("payroll/approve", {
    json: payload,
    retry: { limit: RETRY_LIMIT },
  });

export const undoPayrollAction = (hoursId: number) =>
  financeApi.post("payroll/undo", {
    json: { hoursId },
    retry: { limit: RETRY_LIMIT },
  });

export type UpdatePayrollHoursStatusPayload = {
  userId: string;
  jobId: string;
  shift: number;
  status: number;
  reason?: number;
  note?: string;
};

export const updatePayrollHoursStatus = (payload: UpdatePayrollHoursStatusPayload) =>
  financeApiPlainTextResponse
    .post("payroll/updateHoursStatus", {
      json: payload,
      retry: { limit: RETRY_LIMIT },
    })
    .json<number>();

export type UpdatePayrollHoursRequest = {
  id: number;
  shiftStart?: string;
  shiftEnd?: string;
  lunchDuration?: string; // lunch duration in hours
};

export const updatePayrollHours = (payload: UpdatePayrollHoursRequest) =>
  financeApi.post("payroll/updateHours", {
    json: payload,
    retry: { limit: RETRY_LIMIT },
  });

export type CreateIntercomDisputePayload = {
  cmExternalId: string;
  wpExternalId: string;
  initialMessage: string;
  companyName: string;
  shiftDate: string;
  adjustmentReason: string;
  onsiteSupervisorName?: string;
};

export const createIntercomDispute = (payload: CreateIntercomDisputePayload) =>
  apiV1WithErrorRedirect.post("intercom/dispute", {
    json: payload,
    retry: { limit: RETRY_LIMIT },
  });

export type CompaniesResponse = {
  id: number;
  name: string;
  accountManager?: string;
  accountManagerId?: number;
};


export type ShiftDuration = {
  hours: number;
  minutes: number;
}
