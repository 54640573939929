/* eslint-disable import/no-cycle */
import React from "react";
import { ShiftAdjustTimePicker } from "./ShiftAdjustTimePicker";
import {ShiftAdjustBodyContainer, ShiftAdjustTimeArrowContainer} from "./styledComponents";
import { ShiftAdjustBreakDuration } from "./ShiftAdjustBreakDuration";
import { TimeArrow } from "../arrow";

export type ShiftAdjustBodyProps = {
  setClockIn: (d: Date) => void;
  setClockOut: (d: Date) => void;
  setBreakDurationMins: (duration?: number) => void;
  breakDurationMins?: number;
  clockIn?: Date;
  clockOut?: Date;
  formattedTimezone?: string;
};

export const ShiftAdjustBody = ({
  clockIn,
  clockOut,
  breakDurationMins,
  setClockIn,
  setClockOut,
  setBreakDurationMins,
  formattedTimezone,
}: ShiftAdjustBodyProps) => {
  return (
    <ShiftAdjustBodyContainer>
      <ShiftAdjustTimePicker
        label={"Clock-in"}
        setDate={setClockIn}
        date={clockIn}
        formattedTimezone={formattedTimezone}
      />
      <ShiftAdjustTimeArrowContainer>
        <TimeArrow />
      </ShiftAdjustTimeArrowContainer>
      <ShiftAdjustBreakDuration
        label={"Total Break"}
        breakDuration={breakDurationMins}
        setBreakDuration={setBreakDurationMins}
      />
      <ShiftAdjustTimeArrowContainer>
        <TimeArrow />
      </ShiftAdjustTimeArrowContainer>
      <ShiftAdjustTimePicker
        label={"Clock-out"}
        setDate={setClockOut}
        date={clockOut}
        formattedTimezone={formattedTimezone}
      />
    </ShiftAdjustBodyContainer>
  );
};
